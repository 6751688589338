import { useEffect, useState } from "react";
import logo from "./assets/Logo.svg";
import shorts from "./assets/shorts.svg";
import { Link, useNavigate } from "react-router-dom";
import { handleUserLoginWithPassword } from "../../controllers/UserController";
import StagingCheckModal from './components/StagingCheckModal';
import LoginOTP from "./loginOTP";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [view, setView] = useState("password"); // State to toggle between views
  const [isStaging, setIsStaging] = useState(false);
  const navigate = useNavigate();

  const environment = process.env.REACT_APP_ENVIRONMENT;

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSuccess = (response) => {
    if (response) navigate("/dashboard");
  };

  const handleLoginClick = () => {
    if (!email) {
      setError("Email is required");
      return;
    }
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    setError("");

    const userData = { email, password };
    handleUserLoginWithPassword(userData, onSuccess);
  };

  useEffect(() => {
    const storedItem = localStorage.getItem("dora_token");
    if (storedItem) {
      navigate("/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    if (environment === "development") {
      setIsStaging(true);
    }
  }, []);


  return (

    <div className="w-full h-[100vh] flex justify-center items-center bg-[#f1f1f1] text-[#000]">
      <div className="p-6 w-full max-w-[1280px] lg:max-h-[630px] bg-[#fff] h-full flex rounded-[15px] flex-row justify-center lg:justify-between">
        <div className="w-full lg:w-[49%] relative h-full flex py-6 md:py-0 flex-col items-center justify-center">


          <StagingCheckModal
            isVisible={isStaging}
          />

          <Link to="/" className="cursor-pointer">
            <img src={logo} alt="Dorascribe" />
          </Link>
          <p className="mt-6 font-BricolageGrotesque font-semibold text-2xl text-black">
            Login
          </p>
          <p className="mt-1 text-[#282D2D] font-Inter font-normal text-center text-lg">
            Welcome back to your our medical AI assistant
          </p>

          <div className="w-full md:w-[500px] p-1 flex flex-row border border-[#EAEBF0] rounded-[60px] mt-2">
            <button
              className={`w-1/2 py-3 rounded-[45px] text-center font-Inter font-medium transition-all text-base ${view === "password"
                ? "bg-[#00AAAA] text-white"
                : "text-[#282D2D]"
                }`}
              onClick={() => setView("password")}
            >
              Login with Password
            </button>
            <button
              className={`w-1/2 py-3 rounded-[45px] text-center font-Inter font-medium transition-all text-base ${view === "otp" ? "bg-[#00AAAA] text-white" : "text-[#282D2D]"
                }`}
              onClick={() => setView("otp")}
            >
              Login with OTP
            </button>
          </div>
          {view === "password" && (
            <>
              <input
                type="text"
                className="mt-3 border border-[#EAEBF0] w-full md:w-[500px] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
                placeholder="Enter your Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <input
                type="password"
                className="mt-3 border border-[#EAEBF0] w-full md:w-[500px] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
                placeholder="Enter your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Link to="/forgot-password">
                <p className=" text-right w-full px-2 mt-2 font-Inter text-[#282D2D] cursor-pointer text-base md:w-[500px]">
                  Forgot Password?
                </p>
              </Link>
              {error && (
                <p className="mt-2 text-red-600 font-Inter font-normal text-sm">
                  {error}
                </p>
              )}
              <button
                className="w-full md:w-[500px] rounded-[50px] bg-[#0dabab] text-base font-semibold text-white font-BricolageGrotesque py-4 mt-6"
                onClick={handleLoginClick}
              >
                Proceed
              </button>
            </>
          )}

          {view === "otp" && <LoginOTP />}
          <p className="absolute bottom-0 text-center text-[#12121266] font-Inter text-base font-normal">
            Don’t have an Account?{" "}
            <Link to="/signUp">
              <span className="text-[#0DABAB] font-semibold">Sign up</span>
            </Link>
          </p>
        </div>
        <div
          style={{
            backgroundImage: `url(${shorts})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="w-full lg:w-[49%] bg-[#f1f1f1] rounded-[20px] relative hidden lg:flex flex-col justify-center"
        ></div>
      </div>
    </div>
  );
};

export default Login;
