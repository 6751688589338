import React, { useState, useEffect } from 'react';

const TutorialModal = ({ isVisible, onClose }) => {
	const [countdown, setCountdown] = useState(5);

	useEffect(() => {
		if (isVisible) {
			// Reset countdown when modal becomes visible
			setCountdown(5);

			const interval = setInterval(() => {
				setCountdown((prev) => {
					if (prev > 1) {
						return prev - 1;
					} else {
						clearInterval(interval);
						return 0;
					}
				});
			}, 1000);

			// Cleanup interval on component unmount or when modal is closed
			return () => clearInterval(interval);
		}
	}, [isVisible]);

	if (!isVisible) return null;

	return (
		<div className='w-full md:w-[120%] h-full bg-[#1212128d] z-[99999] fixed top-0 md:-left-[20%] px-6 flex justify-center items-center'>
			<div className='w-full flex justify-center items-center'>
				<div className='md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-auto p-6 rounded-[15px] w-full md:w-[1200px] relative'>
					<button className='bg-red-500 text-white px-4 py-2 rounded-md absolute top-1 right-4' onClick={onClose}>
						Finish
					</button>
					<div className='w-full h-full bg-white'>
						<img src="/img.gif" alt="Permission required" className="max-w-full max-h-full" />
					</div>
				</div>
			</div>
		</div>


	);
};

export default TutorialModal;

