import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	getTemplateExample,
	getTemplateExampleById,
	handleCreateNoteTemplates,
} from '../../../controllers/NoteController';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import UserTemplateNotes from './UserTemplateNotes';
import DOMPurify from 'dompurify';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const SidebarNavItem = ({ label, isSelected, onClick }) => (
	<div
		className={`w-full text-center md:text-left p-4 cursor-pointer ${
			isSelected ? 'bg-[#33CCCC] bg-opacity-50 text-[#007777]' : 'text-gray-600'
		} hover:bg-opacity-25`}
		onClick={onClick}
	>
		{label}
	</div>
);

const Tag = ({ label, onClick, isSelected }) => (
	<span
		className={`px-3 py-1 text-xs rounded-full cursor-pointer mr-2 mb-2 ${
			isSelected ? 'bg-[#00AAAA] text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
		}`}
		onClick={onClick}
	>
		{label}
	</span>
);

const UserTemplateModal = ({ isVisible, onClose }) => {
	const [activeTab, setActiveTab] = useState('New Template');
	const [templates, setTemplates] = useState([]);
	const [formState, setFormState] = useState({
		templateName: '',
		templateContent: '',
		readOnlyName: '',
		readOnlyContent: '',
		templateDependencyId: '',
		errors: {
			templateName: '',
			templateContent: '',
			templateDependencyId: '',
		},
	});
	const [isLoading, setIsLoading] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);
	// const [isPromptLoading, setIsPromptLoading] = useState(false);
	const [visibleCount, setVisibleCount] = useState(10);
	const textareaRef = useRef(null);

	// Fetch templates on mount
	useEffect(() => {
		if (isVisible && activeTab === 'New Template') {
			setIsLoading(true);
			getTemplateExample()
				.then((response) => {
					setTemplates(response.data);
					setIsLoading(false);
				})
				.catch((error) => console.error('Error fetching templates:', error));
		}
	}, [isVisible, activeTab]);

	// Populate templateName with readOnlyName if readOnlyName is set and templateName is still empty
	useEffect(() => {
		if (formState.readOnlyName) {
			setFormState((prevState) => ({
				...prevState,
				templateName: formState.readOnlyName,
			}));
		}
	}, [formState.readOnlyName]);

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormState((prev) => ({
			...prev,
			[id]: value,
			errors: { ...prev.errors, [id]: '' },
		}));

		if (id === 'templateContent') {
			const textarea = textareaRef.current;
			textarea.style.height = 'auto'; // Reset height
			textarea.style.height = `${textarea.scrollHeight}px`; // Adjust to new content
		}
	};

	const handleTagClick = async (id) => {
		// setIsPromptLoading(true);
		try {
			const response = await getTemplateExampleById(id);
			setFormState((prev) => ({
				...prev,
				templateDependencyId: response.data.id,
				readOnlyName: response.data.name,
				readOnlyContent: DOMPurify.sanitize(response.data.example_content),
				errors: { ...prev.errors, templateDependencyId: '' },
			}));
		} catch (error) {
			console.error('Error fetching template details:', error);
		} finally {
			// setIsPromptLoading(false);
		}
	};

	const validateForm = () => {
		const errors = {};
		if (!formState.templateName.trim()) {
			errors.templateName = 'Template name is required.';
		} else if (formState.templateName.length < 3) {
			errors.templateName = 'Template name must be at least 3 characters.';
		}

		if (!formState.templateDependencyId) {
			errors.templateDependencyId = 'Please select a template from the list.';
		}

		setFormState((prev) => ({ ...prev, errors }));
		return Object.keys(errors).length === 0;
	};

	const handleSave = async () => {
		if (!validateForm()) return;

		setButtonLoading(true);

		try {
			const payload = {
				name: formState.templateName,
				full_prompt_description: formState.templateContent ? formState.templateContent : null,
				template_dependency_id: formState.templateDependencyId,
			};

			const res = await handleCreateNoteTemplates(payload);

			if (res) toast.success('Template saved successfully!');
			onClose();
		} catch (error) {
			console.error('Error saving template:', error);
			toast.error('Failed to save template.');
		} finally {
			setButtonLoading(false);
		}
	};

	const handleViewMore = () => setVisibleCount((prev) => prev + 10);

	if (!isVisible) return null;

	return (
		<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999]'>
			<div className='bg-white w-full max-w-7xl h-full md:h-4/5 rounded-lg shadow-lg flex flex-col md:flex-row overflow-auto relative'>
				<button onClick={onClose} className='absolute top-3 right-3 hidden md:block'>
					<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
						<path
							fill='currentColor'
							d='m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275t.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275z'
						/>
					</svg>
				</button>

				{/* Sidebar */}
				<div className='w-full text-sm md:text-base flex md:flex-col md:w-1/5 shadow'>
					{['New Template', 'My Templates', 'Close'].map((tab) => (
						<SidebarNavItem
							key={tab}
							label={tab}
							isSelected={activeTab === tab}
							onClick={() => (tab === 'Close' ? onClose() : setActiveTab(tab))}
						/>
					))}
				</div>

				{/* Main Content */}
				<div
					className={`w-full md:w-3/4 p-4 md:p-6 md:overflow-auto ${activeTab === 'New Template' ? 'md:border-r' : ''}`}
				>
					{activeTab === 'New Template' && (
						<>
							<h2 className='text-lg md:text-xl font-semibold mb-4'>Create Custom Note</h2>

							{/* Template Name Input */}
							<div className='mb-4'>
								<label htmlFor='templateName' className='block text-sm font-medium text-gray-700'>
									Template Name
								</label>
								<input
									id='templateName'
									type='text'
									className={`mt-1 p-2 border w-full rounded ${formState.errors.templateName ? 'border-red-500' : ''}`}
									placeholder='Enter template name'
									value={formState.templateName}
									onChange={handleInputChange}
								/>
								{formState.errors.templateName && (
									<p className='text-red-500 text-sm mt-1'>{formState.errors.templateName}</p>
								)}
							</div>

							{/* Template Tags */}
							<div className='mb-4'>
								<label className='block text-sm font-medium text-gray-700 mb-2'>Select a Template</label>
								<div className='flex flex-wrap'>
									<SkeletonTheme color='#202020' highlightColor='#aaa'>
										{isLoading ? (
											<Skeleton height={25} width={100} count={3} style={{ marginBottom: '8px' }} />
										) : (
											templates
												.slice(0, visibleCount)
												.map((template) => (
													<Tag
														key={template.id}
														label={template.name}
														isSelected={formState.templateDependencyId === template.id}
														onClick={() => handleTagClick(template.id)}
													/>
												))
										)}
									</SkeletonTheme>
								</div>
								{formState.errors.templateDependencyId && (
									<p className='text-red-500 text-sm mt-2'>{formState.errors.templateDependencyId}</p>
								)}

								{/* View More Button */}
								{visibleCount < templates.length && (
									<button
										className='mt-4 px-3 py-1 text-xs bg-[#00AAAA] text-white rounded-[30px]'
										onClick={handleViewMore}
									>
										View More
									</button>
								)}
							</div>

							{/* Template Content Textarea */}
							<div className='mb-6'>
								<label htmlFor='templateContent' className='block text-sm font-medium text-gray-700'>
									Input Custom Instructions
								</label>
								<textarea
									ref={textareaRef}
									id='templateContent'
									className={`mt-1 p-2 border w-full rounded resize-vertical ${
										formState.errors.templateContent ? 'border-red-500' : ''
									}`}
									placeholder='Input custom instructions here to modify selected note type'
									value={formState.templateContent}
									onChange={handleInputChange}
								/>
								{formState.errors.templateContent && (
									<p className='text-red-500 text-sm mt-1'>{formState.errors.templateContent}</p>
								)}
							</div>

							{/* Save Button */}
							<button
								className={`bg-[#00AAAA] text-white rounded-[30px] px-6 py-3 w-full md:max-w-40 ${
									buttonLoading ? 'bg-opacity-60 cursor-not-allowed' : ''
								}`}
								onClick={handleSave}
								disabled={buttonLoading}
							>
								{!buttonLoading ? (
									<div className='text-center'>Save Template</div>
								) : (
									<div className='flex items-center justify-center gap-2'>
										<div className='loader-1'></div>
										<div>Saving...</div>
									</div>
								)}
							</button>
						</>
					)}

					{/* Notes Tab */}
					{activeTab === 'My Templates' && (
						<>
							<h2 className='text-lg md:text-xl font-semibold'>My Templates</h2>
							<UserTemplateNotes />
						</>
					)}
				</div>

				{activeTab === 'New Template' && (
					<div className='w-full max-w-xl md:w-3/4'>
						<div className='grid h-full p-8 text-sm overflow-y-auto'>
							{formState.readOnlyName ? (
								<div>
									<h5 className='text-base md:text-lg mb-4 font-medium'>{formState.readOnlyName}</h5>
									<ReactMarkdown
										children={formState.readOnlyContent ? formState.readOnlyContent : 'Not available'}
										remarkPlugins={[remarkGfm]}
										rehypePlugins={[rehypeRaw]}
										components={{
											strong: ({ node, ...props }) => <strong className='bold-text' {...props} />,
											p: ({ node, ...props }) => <p className='paragraph-text' {...props} />,
										}}
									/>
								</div>
							) : (
								<p className='grid place-items-center h-full italic text-gray-500'>No selected template</p>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default UserTemplateModal;
