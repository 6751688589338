import { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActivePageContext, SidebarContext } from '../contexts/ActivePageContext';
import {
	handleAllNotes,
	handleAllDeletedNotes,
	handleDeleteNoteById,
	handleGetRestoreNote,
	handleDeleteNotePermanently,
} from '../../../controllers/NoteController';
import { getOS } from '../../../utils';
import useLocalStorageListener from '../hooks/useLocalStorageListener';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { formatDate } from '../../../utils/dateUtils';
import UserFormModal from './UserFormModal';
import UserTemplateModal from './UserTemplateModal';
import DropdownMenu from './DropdownMenu';
import BackToTop from './BackToTop';
import logo from './assets/Logo.svg';
import plus from './assets/plus-white.svg';
import recent from './assets/recent.svg';
import search from './assets/search-normal.svg';
import dots from './assets/DotsThreeOutlineVertical.svg';
import trash from './assets/trash-03.svg';
import close from './assets/close cross.svg';
import { Tooltip } from 'react-tooltip';

const Sidebar = () => {
	const [activeTab, setActiveTab] = useState('recent');
	const [startRecording, setStartRecording] = useState(false);
	const [isTemplateModalVisible, setTemplateModalVisible] = useState(false); // New state for template modal
	const { setActivePage } = useContext(ActivePageContext);
	const { sidebarVisible, setSidebarVisible } = useContext(SidebarContext);
	const [notes, setNotes] = useState([]);
	const [deletedNotes, setDeletedNotes] = useState([]);
	const [dropdownVisible, setDropdownVisible] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const dropdownRef = useRef(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [os, setOS] = useState('');
	const [patientInfo, setPatientInfo] = useLocalStorageListener('patient_info', null);
	const [noteLoader, setNoteLoader] = useLocalStorageListener('note_load', null);
	const Navigate = useNavigate();

	const fetchNotes = async () => {
		const data = await handleAllNotes();
		if (data) {
			setIsLoading(false);
			setNotes(data);
		}
	};

	const fetchDeletedNotes = async () => {
		const response = await handleAllDeletedNotes();
		if (response) {
			setIsLoading(false);
			setDeletedNotes(response.data.data);
		}
	};

	useEffect(() => {
		fetchNotes();
		fetchDeletedNotes();
	}, []);

	useEffect(() => {
		const storedPatientInfo = localStorage.getItem('patient_info');
		if (storedPatientInfo) setPatientInfo(JSON.parse(storedPatientInfo));
	}, [setPatientInfo]);

	useEffect(() => {
		const userOS = getOS();
		setOS(userOS);
	}, []);

	useEffect(() => {
		if (os === 'iOS') {
			const storedNoteId = localStorage.getItem('note_id');
			const dataLoader = {
				note_id: JSON.parse(storedNoteId),
				is_note: false,
			};

			setTimeout(() => {
				setNoteLoader(dataLoader);
			}, 2500);
		} else {
			const isNoteLoading = localStorage.getItem('note_load');
			if (isNoteLoading) setNoteLoader(JSON.parse(isNoteLoading));
		}
	}, [os, setNoteLoader]);

	useEffect(() => {
		if (patientInfo) {
			fetchNotes();
		}
	}, [patientInfo]);

	const handlePatientCreated = (data) => {
		if (data.res) fetchNotes();
	};

	const handleStartRecording = () => {
		setStartRecording(true);
		setActivePage('Home');
	};

	const handleClick = (page) => {
		setStartRecording(false);
		setActivePage(page);
		Navigate('/dashboard');
	};

	const toggleDropdown = (noteId) => {
		setDropdownVisible((prev) => (prev === noteId ? null : noteId));
	};

	const handleOpenNote = (noteId) => {
		setDropdownVisible(null);
		localStorage.setItem('note_id', JSON.stringify(noteId));
		Navigate('/dashboard');
		setActivePage('Transcribed');
	};

	const handleEditNote = (noteId) => {
		setDropdownVisible(null);
		localStorage.setItem('note_id', JSON.stringify(noteId));
		Navigate('/transcription');
		window.location.reload();
	};

	const handleDeleteNote = (noteId) => {
		handleDeleteNoteById(noteId).then(() => {
			fetchNotes();
			fetchDeletedNotes();
			setActivePage('Home');
		});
		setDropdownVisible(null);
	};

	const handleRestoreNote = (noteId) => {
		handleGetRestoreNote(noteId).then(() => {
			fetchNotes();
			fetchDeletedNotes();
		});
		setDropdownVisible(null);
	};

	const handleDeleteFromTrash = (noteId) => {
		handleDeleteNotePermanently(noteId).then(() => {
			fetchDeletedNotes();
		});
		setDropdownVisible(null);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownVisible(null);
		}
	};

	useEffect(() => {
		if (dropdownVisible !== null) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownVisible]);

	const handleSearchChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const filteredNotes = notes.filter(
		(note) =>
			(note.patient_name && note.patient_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
			(note.created_at && formatDate(note.created_at).toLowerCase().includes(searchQuery.toLowerCase()))
	);

	const handleCloseModal = () => {
		setStartRecording(false);
	};

	const handleCreateTemplateNote = () => {
		setTemplateModalVisible(true);
	};

	const handleTemplateModalClose = () => {
		setTemplateModalVisible(false);
	};

	return (
		<>
			{startRecording && (
				<UserFormModal
					isVisible={startRecording}
					onClose={handleCloseModal}
					handleClick={handleClick}
					onPatientCreated={handlePatientCreated}
				/>
			)}

			{/* Create New Template Modal */}
			{isTemplateModalVisible && (
				<UserTemplateModal isVisible={isTemplateModalVisible} onClose={handleTemplateModalClose} />
			)}

			<div
				className={`fixed top-0 left-0 z-[999] w-[80%] md:w-[40%] lg:w-[25%] h-screen bg-[#F5F5F5] ${
					sidebarVisible ? 'lg:translate-x-0 translate-x-0' : 'lg:translate-x-0 -translate-x-full'
				} transition-transform`}
			>
				<div className='w-full h-full relative flex flex-col items-start'>
					<div className='w-full flex justify-between border-b min-h-[70px] px-6 border-[#EAEBF0]'>
						<img src={logo} onClick={() => handleClick('Home')} className='w-32 cursor-pointer' alt='Logo' />
						<img
							src={close}
							onClick={() => setSidebarVisible(false)}
							className='cursor-pointer lg:hidden w-5'
							alt='Close'
						/>
					</div>
					<div className='w-full flex flex-col items-center px-6 mt-5'>
						<div className='w-full flex items-start justify-between'>
							<button
								onClick={handleStartRecording}
								className='justify-center py-2 px-3 bg-[#0DABAB] rounded-[48px] flex items-center space-x-1'
								data-tooltip-id='add-s'
							>
								<img src={plus} className='w-[24px] h-[24px]' alt='Plus' />
								<p className='text-[#fff] font-BricolageGrotesque font-semibold text-sm'>Start Consult</p>
							</button>
							<button
								onClick={handleCreateTemplateNote}
								className='justify-center py-2 px-3 bg-[#0DABAB] rounded-[48px] flex items-center space-x-1'
								data-tooltip-id='cus-temp'
							>
								<img src={plus} className='w-[24px] h-[24px]' alt='Plus' />
								<p className='text-[#fff] font-BricolageGrotesque font-semibold text-sm'>Create Notes</p>
							</button>
							<Tooltip
								id='add-s'
								place='bottom'
								variant='dark'
								content='Click to create a new patient medical note recording.'
							/>
							<Tooltip id='cus-temp' place='bottom' variant='dark' content='Click to create a custom note template.' />
						</div>
						<div className='w-full flex items-center justify-between'>
							<div
								onClick={() => setActiveTab('recent')}
								className={`mt-4 items-center flex w-[50%] space-x-4 justify-center py-3 border-b-[3px] ${
									activeTab === 'recent' ? 'border-[#00aaaa]' : 'border-[#f5f5f5]'
								}`}
							>
								<div className='flex cursor-pointer' data-tooltip-id='recent'>
									<img src={recent} alt='Recent' />
									<p className='font-Inter font-semibold text-[10px] text-black ml-2'>Recent Notes</p>
								</div>
								<button className='bg-[#E1EAEA] rounded-[5px] py-[2px] px-2 text-center font-Inter font-semibold text-sm text-[#00aaaa]'>
									{notes.length}
								</button>
								<Tooltip id='recent' place='top' variant='dark' content='Click to view your recent medical notes.' />
							</div>
							<div
								onClick={() => setActiveTab('trash')}
								className={`mt-4 items-center flex w-[50%] space-x-4 justify-center py-3 border-b-[3px] ${
									activeTab === 'trash' ? 'border-[#00aaaa]' : 'border-[#f5f5f5]'
								}`}
								data-tooltip-id='trash'
							>
								<span className='flex items-center space-x-3 cursor-pointer'>
									<img src={trash} alt='Trash' />
									<p className='text-[10px] font-Inter font-semibold text-black'>Trash</p>
								</span>
								<button className='py-[2px] px-2 rounded-[5px] bg-[#EEE4E3] text-center text-sm text-[#E2341D] font-Inter font-semibold'>
									{deletedNotes.length}
								</button>
							</div>
							<Tooltip id='trash' place='top' variant='dark' content='Click to view your deleted medical notes.' />
						</div>
						{activeTab === 'recent' && (
							<div className='relative w-full mt-4'>
								<img src={search} className='absolute top-[15px] left-2.5' alt='Search' />
								<input
									type='search'
									value={searchQuery}
									onChange={handleSearchChange}
									className='w-full rounded-[48px] py-2.5 pr-2.5 pl-8 bg-white font-Inter text-sm font-normal text-black placeholder:text-black'
									placeholder='Search...'
									data-tooltip-id='search-tooltip'
									data-tooltip-content='You can search by patient name or date'
								/>
								<Tooltip id='search-tooltip' place='right' variant='dark' />
							</div>
						)}

						<div className='overflow-y-auto h-[60vh] mt-3 w-full space-y-2'>
							<SkeletonTheme color='#202020' highlightColor='#aaa'>
								{isLoading ? (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Skeleton height={25} count={5} />
									</div>
								) : (
									(activeTab === 'recent' ? filteredNotes : deletedNotes).map((note) => (
										<div key={note.id} className='relative'>
											<button className='w-full flex py-3 px-6 rounded-[10px] justify-between border-[#EAEBF0] border items-center'>
												<span className='flex flex-col items-start'>
													{noteLoader?.is_note && noteLoader?.note_id === note.id ? (
														<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
															<Skeleton height={15} width={75} count={1} />
															<Skeleton height={15} width={150} count={1} />
														</div>
													) : (
														<div onClick={activeTab === 'recent' ? () => handleOpenNote(note.id) : null}>
															<p className='text-[#3e2121] font-BricolageGrotesque font-semibold text-sm text-left'>
																{note.patient_name ? note.patient_name : 'Not set'}
															</p>
															<p className='text-[#282D2D] font-Inter font-normal text-sm'>
																{formatDate(note.created_at)}
															</p>
														</div>
													)}
												</span>
												{noteLoader?.is_note && noteLoader?.note_id === note.id ? (
													<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
														<Skeleton height={30} width={15} count={1} />
													</div>
												) : (
													<img src={dots} alt='Option' onClick={() => toggleDropdown(note.id)} />
												)}
											</button>
											{dropdownVisible === note.id && (
												<div ref={dropdownRef}>
													{activeTab === 'recent' ? (
														<DropdownMenu
															options={[
																{
																	label: 'Open',
																	onClick: () => handleOpenNote(note.id),
																},
																{
																	label: 'Edit',
																	onClick: () => handleEditNote(note.id),
																},
																{
																	label: 'Delete',
																	onClick: () => handleDeleteNote(note.id),
																	textColor: 'text-[#E2341D]',
																	fontWeight: 'font-semibold',
																},
															]}
														/>
													) : (
														<DropdownMenu
															options={[
																{
																	label: 'Restore',
																	onClick: () => handleRestoreNote(note.id),
																},
																{
																	label: 'Delete',
																	onClick: () => handleDeleteFromTrash(note.id),
																	textColor: 'text-[#E2341D]',
																	fontWeight: 'font-semibold',
																},
															]}
														/>
													)}
												</div>
											)}
										</div>
									))
								)}
							</SkeletonTheme>
							{activeTab === 'trash' && deletedNotes && deletedNotes.length === 0 && (
								<div className='border rounded-2xl p-2 text-center'>
									<img src={trash} className='d-block mx-auto mb-2' alt='Trash' />
									<p>
										View deleted notes here.
										<br /> Restore them within 14 days.
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<BackToTop />
		</>
	);
};

export default Sidebar;

