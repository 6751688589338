import { useEffect } from "react";

const RedirectToSubdomain = ({ subdomainPath }) => {
  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT;

    // Only redirect if the environment is NOT 'development'
    if (environment !== "development") {
      const baseUrl = "https://dorascribe.ai"; // Update this for your main subdomain
      window.location.href = `${baseUrl}${subdomainPath}`;
    }
  }, [subdomainPath]);

  return null; // No UI required for redirects
};

export default RedirectToSubdomain;
