import React, { useState, useEffect } from 'react';

const StagingCheckModal = ({ isVisible, onClose }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const passwordENV = process.env.REACT_APP_PASSWORD_STAGING;
  const checkAuthentication = () => {
    const savedTime = localStorage.getItem('authTime');
    const currentTime = new Date().getTime();
    console.log('Saved Auth Time:', savedTime);
    console.log('Current Time:', currentTime);

    if (!savedTime || currentTime - savedTime > 3600000) {
      console.log('Authentication expired or not found');
      setIsAuthenticated(false);
    } else {
      console.log('Authentication still valid');
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    console.log(passwordENV);
    if (password === passwordENV) {
      const currentTime = new Date().getTime();
      console.log('Password correct, saving auth time:', currentTime);
      localStorage.setItem('authTime', currentTime);
      setIsAuthenticated(true);
      onClose();
    } else {
      console.log('Incorrect password');
      setError('Incorrect password. Please try again.');
    }
  };

  if (!isVisible || isAuthenticated) return null;

  return (
    <div className="fixed inset-0 bg-[#1212128d] flex justify-center items-center z-[99999]">
      <div className="bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-sm relative">
        <h2 className="text-xl font-semibold mb-4 text-center">Enter Password</h2>
        <form onSubmit={handlePasswordSubmit} className="flex flex-col">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className="border border-gray-300 rounded-md px-4 py-2 mb-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
            Submit
          </button>
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default StagingCheckModal;
