import { useState } from 'react';
import { handleUserNoteInteraction } from '../../../controllers/NoteController';
import text from './assets/text.svg';
import paperPlane from './assets/PaperPlaneRight.svg';

const UserInteractWithNote = ({ interactiveLoader, noteId, onInteractiveNote }) => {
	const [inputValue, setInputValue] = useState('');

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleSend = async () => {
		interactiveLoader(true);
		if (inputValue.trim()) {
			setInputValue(''); // Clear input
			try {
				const payload = {
					action: 'interaction_note',
					content: inputValue,
				};

				const response = await handleUserNoteInteraction(noteId, payload);
				onInteractiveNote(response.data);
				interactiveLoader(false);
			} catch (error) {
				console.error('Error updating note interaction:', error);
			}
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault(); // Prevent newline
			handleSend();
		}
	};

	return (
		<div className='w-full relative'>
			<textarea
				className='bg-[#F5F5F5] px-12 py-4 w-full relative border border-[#EAEBF0] font-Inter font-normal text-base text-black placeholder:text-[#282D2D] resize-none overflow-hidden focus:outline-[#0DABAB]'
				placeholder='Interact with Note'
				value={inputValue}
				onChange={handleInputChange}
				onKeyDown={handleKeyPress}
				rows={1}
				style={{
					height: 'auto',
					overflow: 'hidden',
				}}
				onInput={(e) => {
					e.target.style.height = 'auto';
					e.target.style.height = `${e.target.scrollHeight}px`;
				}}
			/>
			<img src={text} className='absolute left-2 bottom-4 w-8' alt='' />
			<img src={paperPlane} className='absolute right-2 bottom-[30%] w-8 cursor-pointer' alt='' onClick={handleSend} />
		</div>
	);
};

export default UserInteractWithNote;
