import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ActivePageProvider from "./pages/dashboard/contexts/ActivePageContext";
import 'react-tooltip/dist/react-tooltip.css';
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;


Sentry.init({
	dsn: SENTRY_DSN,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: [
		"localhost",
		"https://www.staging.dorascribe.ai",
		"app.dorascribe.ai"
	],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Router>
			<ActivePageProvider>
				<App />
			</ActivePageProvider>
		</Router>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

