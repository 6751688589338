import api from '../api';
import * as Sentry from '@sentry/react';


export const handleInitializedUserNote = async (userData, onSuccess) => {
	try {
		const response = await api('post', '/notes', userData);
		onSuccess(response.data);
	} catch (error) {
		// Define informações do usuário, se disponíveis
		if (userData.userId) {
			Sentry.setUser({
				id: userData.userId,
				email: userData.email || 'N/A', // Caso você tenha o email do usuário
			});
		}

		// Captura o erro com detalhes adicionais
		Sentry.captureException(error, {
			tags: {
				function: 'handleInitializedUserNote',
				status: 'error',
			},
			extra: {
				description: 'Failed to create a note',
				payload: userData,
				errorDetails: error.message,
				stackTrace: error.stack,
			},
		});

		console.log('Failed to create a note:', error);
	}
};




export const handleGetApplicationSettings = async () => {
	try {
		const response = await api('get', '/application_settings');
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};

export const handleGetAssemblyTempToken = async () => {
	try {
		const response = await api('get', '/transcription-token-aai');
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};

export const handleAllNotes = async () => {
	try {
		const response = await api('get', '/notes');
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};

export const handleAllDeletedNotes = async () => {
	try {
		return await api('get', `/note-deleted`);
	} catch (error) {
		console.log(error);
	}
};

export const handleGetRestoreNote = async (noteId) => {
	try {
		return await api('get', `/note-restore/${noteId}`);
	} catch (error) {
		console.log(error);
	}
};

export const handleGetNoteById = async (noteId) => {
	try {
		return await api('get', `/notes/${noteId}`);
	} catch (error) {
		console.log(error);
	}
};

export const handleUserUpdateNote = async (noteId, noteData) => {
    try {
        return await api('put', `/notes/${noteId}`, noteData);
    } catch (error) {
        Sentry.captureException(error, {
            tags: {
                module: 'handleUserUpdateNote',
            },
            extra: {
                noteId,
                noteData,
            },
        });
        console.error('Error updating note:', error);
        throw error;
    }
};

export const handleUserNoteInteraction = async (noteId, noteData) => {
	try {
		const response = await api('put', `/note-interaction/${noteId}`, noteData);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

export const handleGetNoteResponseUpdate = async (noteId) => {
	try {
		return await api('get', `/note-response-update/${noteId}`);
	} catch (error) {
		console.log(error);
	}
};

export const handleNoteChange = async (noteId, noteData) => {
	try {
		noteData.note_id = noteId;
		return await api('post', `/note-template-change`, noteData);
	} catch (error) {
		console.log(error);
	}
};

export const handleNoteResponseUpdate = async (noteId, noteData) => {
	try {
		return await api('put', `/note-response-update/${noteId}`, noteData);
	} catch (error) {
		console.log(error);
	}
};

export const handleDeleteNoteById = async (noteId) => {
	try {
		return await api('delete', `/notes/${noteId}`);
	} catch (error) {
		console.log(error);
	}
};

export const handleDeleteNotePermanently = async (noteId) => {
	try {
		return await api('delete', `/note-permanent-delete/${noteId}`);
	} catch (error) {
		console.log(error);
	}
};

export const getTemplates = async () => {
	try {
		return await api('get', '/templates');
	} catch (error) {
		console.log(error);
	}
};

export const getTemplateById = async (tempId) => {
	try {
		const response = await api('get', `/templates/${tempId}`);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

export const handleCreateNoteTemplates = async (tempData) => {
	try {
		const response = await api('post', '/templates', tempData);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

export const handleUpdateTemplate = async (tempId, tempData) => {
	try {
		return await api('put', `/templates/${tempId}`, tempData);
	} catch (error) {
		console.log(error);
	}
};

export const handleDeleteTemplate = async (tempId) => {
	try {
		return await api('delete', `/templates/${tempId}`);
	} catch (error) {
		console.log(error);
	}
};

export const getTemplateExample = async () => {
	try {
		const response = await api('get', '/template-example');
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

export const getTemplateExampleById = async (tempId) => {
	try {
		const response = await api('get', `/template-example/${tempId}`);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};
