import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Landing from "./pages/landing/landing";
import PricingPage from "./pages/landing/pricing";
// import Resourses from "./pages/landing/resources";
// import DetailedBlog from "./pages/landing/detailedBlog";
import SignUp from "./pages/dashboard/signUp";
import Login from "./pages/dashboard/login";
import Authenticate from "./pages/dashboard/authenticate";
import Dashboard from "./pages/dashboard/dashboard";
import TranscriptionPage from "./pages/dashboard/transcriptionPage";
import "react-loading-skeleton/dist/skeleton.css";
import PasswordReset from "./pages/dashboard/passwordReset";
import NewPassword from "./pages/dashboard/newPassword";
import EmailVerification from "./pages/dashboard/emailVerification";
import ChoosePlan from "./pages/dashboard/choosePlan";
import CardDetailsTrial from "./pages/dashboard/cardDetailsTrial";
import TrialEnd from "./pages/dashboard/trialend";
import PaymentSuccess from "./pages/dashboard/paymentSucess";
import PaymentFailed from "./pages/dashboard/paymenFailed";
import AuthenticateLogin from "./pages/dashboard/authenticateLogin";
import Policy from "./pages/landing/policy";
import Faqs from "./pages/landing/faqs";
import Tutorials from "./pages/landing/tuts";
import ConfirmationPage from "./pages/dashboard/confirmationPage";
import DemoBooked from "./pages/dashboard/demoBooked";
import Thankyou from "./pages/dashboard/thankyou";
import RedirectToSubdomain from "./utils/redirecttosubdomain";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Routes>
        {/* Routes to redirect to dorascribe.ai */}
        <Route
          path="/"
          element={<RedirectToSubdomain subdomainPath="/" />}
        />{" "}
        {/* Home to dorascribe.ai */}
        <Route
          path="/pricing"
          element={<RedirectToSubdomain subdomainPath="/pricing" />}
        />
        <Route
          path="/tutorials"
          element={<RedirectToSubdomain subdomainPath="/tutorials" />}
        />
        <Route
          path="/faqs"
          element={<RedirectToSubdomain subdomainPath="/faqs" />}
        />
        <Route
          path="/privacy-policy"
          element={<RedirectToSubdomain subdomainPath="/privacy-policy" />}
        />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/emailVerify" element={<EmailVerification />} />
        <Route path="/forgot-password" element={<PasswordReset />} />
        <Route path="/PasswordReset" element={<NewPassword />} />
        <Route path="/authenticate" element={<Authenticate />} />
        <Route path="/authenticatelogin" element={<AuthenticateLogin />} />
        <Route path="/chooseplan" element={<ChoosePlan />} />
        {/* <Route path="/trialcarddetails" element={<CardDetailsTrial />} /> */}
        <Route path="/TrialEnd" element={<TrialEnd />} />
        <Route path="/paymentsuccess" element={<PaymentSuccess />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="/paymentfailed" element={<PaymentFailed />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/transcription" element={<TranscriptionPage />} />
        <Route path="/Welcome" element={<ConfirmationPage />} />
        <Route path="/demo" element={<DemoBooked />} />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
