import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	getTemplates,
	getTemplateById,
	getTemplateExample,
	getTemplateExampleById,
	handleUpdateTemplate,
	handleDeleteTemplate,
} from '../../../controllers/NoteController';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

// Validation Schema
const templateSchema = yup.object().shape({
	name: yup.string().required('Template name is required'),
	full_prompt_description: yup.string().required('Template description is required'),
});

const Tag = ({ label, canDelete, onEdit, onDelete }) => (
	<span className='px-3 py-1 bg-gray-200 text-xs text-gray-700 rounded-full flex items-center cursor-pointer mr-2 mb-2 hover:bg-gray-300'>
		<span className='flex-1' onClick={onEdit}>
			{label}
		</span>
		{canDelete && (
			<button
				className='ml-2 text-red-500 hover:text-red-700'
				onClick={(e) => {
					e.stopPropagation(); // Prevent triggering edit
					onDelete();
				}}
			>
				Delete
			</button>
		)}
	</span>
);

const UserTemplateNotes = () => {
	const [templates, setTemplates] = useState([]);
	const [defaultTemplates, setDefaultTemplates] = useState([]);
	const [visibleCount, setVisibleCount] = useState(10);
	const [visibleDefaultCount, setVisibleDefaultCount] = useState(10);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [selectedTemplatePreview, setSelectedTemplatePreview] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isTempLoading, setIsTempLoading] = useState(false);

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(templateSchema),
		mode: 'onSubmit',
	});

	// Fetch templates on mount
	const fetchTemplates = async () => {
		try {
			const response = await getTemplates();
			const sortedUserTemplates = response.data.data.sort((a, b) => b.id - a.id);
			setTemplates(sortedUserTemplates);
		} catch (error) {
			console.error('Error fetching user templates:', error);
			setError('Failed to load templates.');
		}
	};

	const fetchDefaultTemplates = async () => {
		try {
			const response = await getTemplateExample();
			const sortedDefaultTemplates = response.data.sort((a, b) => b.id - a.id);
			setDefaultTemplates(sortedDefaultTemplates);
		} catch (error) {
			console.error('Error fetching default templates:', error);
			setError('Failed to load default templates.');
		}
	};

	useEffect(() => {
		setIsLoading(true); // Set loading to true initially

		// Call both functions
		Promise.all([fetchTemplates(), fetchDefaultTemplates()]).finally(() => {
			setIsLoading(false);
		});
	}, []);

	// Save changes to a template
	const onSubmit = async (data) => {
		try {
			await handleUpdateTemplate(selectedTemplate.id, data);
			toast.success('Template updated successfully!');
			// Refresh template list
			setTemplates((prevTemplates) =>
				prevTemplates.map((template) => (template.id === selectedTemplate.id ? { ...template, ...data } : template))
			);
			setSelectedTemplate(null); // Exit edit mode
		} catch (error) {
			console.error('Error updating template:', error);
			toast.error('Failed to update template.');
		}
	};

	// Handle edit
	const handleEdit = (params) => {
		// if (!params.can_delete) return;

		setIsTempLoading(true);

		getTemplateById(params.id)
			.then((response) => {
				const res = response.data;

				const payload = {
					id: res.id,
					// name: res.name,
					// full_prompt_description: res.full_prompt_description,
					name: '',
					full_prompt_description: '',
					template_dependency_id: res.id,
				};

				reset(payload); // Pre-fill the form with data
				setSelectedTemplate(payload);
				setSelectedTemplatePreview(res);
				setIsTempLoading(false);
			})
			.catch((error) => console.error('Error fetching template:', error));
	};

	// Delete a template
	const handleDelete = async (id) => {
		const confirmDelete = window.confirm('Are you sure you want to delete this template?');
		if (!confirmDelete) return;

		try {
			await handleDeleteTemplate(id);
			// Remove the deleted template from the list
			setTemplates((prevTemplates) => prevTemplates.filter((template) => template.id !== id));
		} catch (error) {
			console.error('Error deleting template:', error);
		}
	};

	// Load more templates
	const handleViewMore = (params) => {
		if (params === 'user') setVisibleCount((prevCount) => prevCount + 10);
		else setVisibleDefaultCount((prevCount) => prevCount + 10);
	};

	return (
		<div className='space-y-6'>
			<p className='text-gray-600'>Manage your templates. Edit or delete existing templates as needed.</p>

			{error && <div className='text-red-500 text-sm'>{error}</div>}

			{/* Display Templates */}
			<div>
				<div className='flex items-center my-4'>
					<div className='flex-grow border-t border-gray-300'></div>
					<span className='mx-4 text-gray-500'>User Template</span>
					<div className='flex-grow border-t border-gray-300'></div>
				</div>

				<div className='flex flex-wrap'>
					<SkeletonTheme color='#202020' highlightColor='#aaa'>
						{isLoading ? (
							<Skeleton height={25} width={100} count={1} style={{ marginBottom: '8px' }} />
						) : (
							templates
								.slice(0, visibleCount)
								.map(
									(template) =>
										template.can_delete && (
											<Tag
												key={template.id}
												label={template.name}
												canDelete={template.can_delete}
												onEdit={() => handleEdit(template)}
												onDelete={() => handleDelete(template.id)}
											/>
										)
								)
						)}
					</SkeletonTheme>
				</div>

				{/* View More Button (USER) */}
				{visibleCount < templates.length && (
					<button
						className='mt-4 px-3 py-1 text-xs bg-[#00AAAA] text-white rounded-[30px]'
						onClick={() => handleViewMore('user')}
					>
						View More
					</button>
				)}

				<div className='flex items-center my-4'>
					<div className='flex-grow border-t border-gray-300'></div>
					<span className='mx-4 text-gray-500'>Default Template</span>
					<div className='flex-grow border-t border-gray-300'></div>
				</div>

				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<div className='md:border-r'>
						<div className='flex flex-wrap'>
							<SkeletonTheme color='#202020' highlightColor='#aaa'>
								{isLoading ? (
									<Skeleton height={25} width={100} count={1} style={{ marginBottom: '8px' }} />
								) : (
									defaultTemplates
										.slice(0, visibleDefaultCount)
										.map(
											(template) =>
												!template.can_delete && (
													<Tag
														key={template.id}
														label={template.name}
														canDelete={template.can_delete}
														onEdit={() => handleEdit(template)}
													/>
												)
										)
								)}
							</SkeletonTheme>
						</div>

						{/* View More Button (DEFAULT) */}
						{visibleDefaultCount < defaultTemplates.length && (
							<button
								className='mt-4 px-3 py-1 text-xs bg-[#00AAAA] text-white rounded-[30px]'
								onClick={() => handleViewMore('default')}
							>
								View More
							</button>
						)}
					</div>

					<div className='w-full'>
						<div className='grid h-full md:p-8 text-sm overflow-y-auto'>
							{selectedTemplatePreview?.name ? (
								<div>
									<h5 className='text-base md:text-lg mb-4 font-medium'>{selectedTemplatePreview?.name}</h5>
									<ReactMarkdown
										children={
											selectedTemplatePreview?.example_content
												? selectedTemplatePreview?.example_content
												: 'Not available'
										}
										remarkPlugins={[remarkGfm]}
										rehypePlugins={[rehypeRaw]}
										components={{
											strong: ({ node, ...props }) => <strong className='bold-text' {...props} />,
											p: ({ node, ...props }) => <p className='paragraph-text' {...props} />,
										}}
									/>
								</div>
							) : (
								<p className='grid place-items-center h-full italic text-gray-500'>No selected template</p>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* Edit Template Section */}
			{selectedTemplate && (
				<div className='p-6 border rounded-lg bg-white shadow-md'>
					<h3 className='text-lg font-bold mb-4'>Edit Template</h3>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='mb-4'>
							<label className='block text-sm font-medium text-gray-700'>Template Name</label>
							<Controller
								name='name'
								control={control}
								render={({ field }) => (
									<input
										type='text'
										className={`mt-1 p-2 border w-full rounded ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
										{...field}
									/>
								)}
							/>
							{errors.name && <p className='text-sm text-red-500 mt-1'>{errors.name.message}</p>}
						</div>
						<div className='mb-4'>
							<label className='block text-sm font-medium text-gray-700'>Template Instructions</label>
							<Controller
								name='full_prompt_description'
								control={control}
								render={({ field }) => (
									<textarea
										className={`mt-1 p-2 border w-full rounded h-32 ${
											errors.full_prompt_description ? 'border-red-500' : 'border-gray-300'
										}`}
										{...field}
									/>
								)}
							/>
							{errors.full_prompt_description && (
								<p className='text-sm text-red-500 mt-1'>{errors.full_prompt_description.message}</p>
							)}
						</div>
						<div className='flex gap-4'>
							<button type='submit' className='bg-[#00AAAA] text-white rounded-[30px] px-6 py-3'>
								Save Changes
							</button>
							<button
								type='button'
								className='bg-gray-300 text-gray-700 rounded-[30px] px-6 py-3'
								onClick={() => setSelectedTemplate(null)}
							>
								Cancel
							</button>
						</div>
					</form>
				</div>
			)}
		</div>
	);
};

export default UserTemplateNotes;
